<template>
    <div class="login_container">
        <el-form class="login_form" ref="form" :model="user">
            <el-form-item>
                <h2 class="login_tit" style="color:#409EFF;">线索管理</h2>
                <h5 style="color:#409EFF;">线索管理必备神器</h5>
                <span style="color:#409EFF;margin-right:5px">账号:</span>
                <el-input prefix-icon="iconfont icon-yonghuming" class="login_account" v-model="user.user_name" placeholder="请输入用户名"></el-input>
            </el-form-item>
            <el-form-item>
                <span style="color:#409EFF;margin-right:5px">密码:</span>
                <el-input prefix-icon="iconfont icon-key-fill" class="login_password" type="password" v-model="user.password" placeholder="请输入密码" show-password></el-input>
            </el-form-item>
            <el-form-item>
                <el-button class="login_button" type="primary" @click="onSubmit">登录</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    name: 'LoginView',
    // 注册组件
    components: {},
    // data() 为变量赋值等
    data() {
        return {
            user: {
                user_name: '',
                password: '',
            }
        }
    },
    created() {
    },

    methods: {
        onSubmit() {
            console.log('submit!');
            const that = this
            that.user.user_name = that.user.user_name.trim()
            that.user.password = that.user.password.trim()
            this.myAxios.post(that.myBaseurl + '/login', that.user)
                .then(function (res) {
                    console.log(res.data.Code)
                    if (res.data.Code === '200') {
                        // 加入缓存
                        sessionStorage.setItem('user', JSON.stringify(res.data.Data))
                        console.log("开始跳转到主页 ... ")
                        that.$router.push({
                            path: '/user/index',
                        })
                    } else {
                        alert(res.data.Msg)
                    }
                })
        }
    }
}

</script>
<style scoped>
.login_button {
    background: green !important;
}

h2 {
    margin: 0px;
    padding: 0px;
    height: 40px;
    line-height: 40px;
    color: #606266;
}

h5 {
    margin: 0px;
    padding: 0px;
    text-align: right;
    font-weight: 100;
    color: #909399;
    height: 40px;
    line-height: 40px;
}

.login_account {
    width: 300px;
}

.login_password {
    width: 300px;
}

.login_container {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("//static.dreamsun.net/static/img/2022-05-21/newbkg.gif")
        no-repeat;
    background-size: cover;
}

.login_form {
    padding: 30px 80px;
    min-width: 300px;
    border: 1px solid silver;
    background-color: rgb(255, 255, 255);
    border-radius: 30px;
    opacity: 0.7;
    /* height: 300px; */
}

.login_button {
    width: 100%;
}

.login_tit {
    text-align: center;
}
</style>
